/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, SyntheticEvent, useEffect, useState,
} from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Modal from '../Modal';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import Button from '../Button';
import { ButtonVariant, OptionRadioField, OptionSelectField } from '../../../types/general';
import {
    Participant,
    ParticipantMemberPayload, ParticipantPayload, ParticipantType, WorkflowParticipantRoleEnum,
    WorkflowType,
} from '../../../types/workflows';
import { RadioGroupWithSearch } from '../RadioGroupWithSearch';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { withWorkflowContext, WorkflowContext } from '../../controllers/WorkflowContext';
import { Regex } from '../../../utils/validations';
import { UserContext, withUserContext } from '../../controllers/UserContext';
import { User } from '../../../types/user';
import { UserCard } from '../UserCard';
import { Alert } from '../Alert';
import { ReactComponent as UserProfileIcon } from '../../../assets/images/user-profile.svg';
import { ReactComponent as BackIcon } from '../../../assets/images/chevron-left.svg';
import { LoadingCircles } from '../LoadingCircles';

interface OwnProps extends TranslationContext, OrganizationsContext, WorkflowContext, UserContext {
    open: boolean;
    onModalClose: () => void;
    onSubmit: (p: Partial<ParticipantPayload>) => Promise<void>;
    onMemberSubmit: (m: ParticipantMemberPayload) => Promise<void>;
    participants: Participant[];
    workflowType: WorkflowType;
}

const AddParticipantModalComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
        getOrganizationMembers,
        getParticipantRoleOptions,
        open,
        onModalClose,
        onSubmit,
        onMemberSubmit,
        participants,
        workflowType,
        getUserByEmail,
    } = props;

    const [type, setType] = useState<ParticipantType>(ParticipantType.EXTERNAL);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState<WorkflowParticipantRoleEnum | null>(null);
    const [memberUserId, setMemberUserId] = useState<string>('');
    const [isLoadingMembers, setIsLoadingMembers] = useState(true);
    const [memberOptions, setMemberOptions] = useState<OptionRadioField<string>[]>([]);
    const [roleOptions, setRoleOptions] = useState<OptionSelectField<string>[]>([]);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [externalParticipant, setExternalParticipant] = useState<User | null>(null);
    const [participantStatus, setParticipantStatus] = useState<number>(0);
    const [externalStep, setExternalStep] = useState<0 | 1>(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    useEffect(() => {
        if (!open) {
            setEmail('');
            setName('');
        }
    }, [open]);

    useEffect(() => {
        getRoleOptions();
        loadMembers();
    }, [participants]);

    useEffect(() => {
        const trimmedValue = email?.toString().trim();
        const emailVerification = new RegExp(Regex.Email);
        if (emailVerification.test(trimmedValue)) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
        setExternalParticipant(null);
        setParticipantStatus(0);
    }, [email]);

    /**
     * Get organization members
     *
     * @remarks
     * Get organization members on the first render
     */
    const loadMembers = async () => {
        if (!organizationSelected?.organization?.id) return;
        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id);
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => {
                const isDisabled = participants.some((p) => p.userId === option.userId);
                const disabledText = isDisabled ? ` ${t('workflows.participants.participantAlreadyInWorkflow')}` : '';

                return {
                    value: option.userId,
                    label: `${option.firstName} ${option.lastName}${disabledText}`,
                    disabled: isDisabled,
                };
            });
            setMemberOptions(membersTransformedOptions);
        }
        setIsLoadingMembers(false);
    };

    /**
     * Filter organization members
     *
     * @remarks
     * Filters members using search word
     */
    const filterOrganizationMembers = async (search: string = '') => {
        if (!organizationSelected?.organization?.id) return;
        setIsLoadingMembers(true);

        const [membersData] = await getOrganizationMembers(organizationSelected.organization.id, search);
        if (membersData) {
            const membersTransformedOptions = membersData.map((option) => (
                {
                    value: option.userId,
                    label: `${option.firstName} ${option.lastName}`,
                }));
            setMemberOptions(membersTransformedOptions);
        }
        setIsLoadingMembers(false);
    };
    const handleSubmit = async (e: FormEvent) => {
        setIsSubmitting(true);
        e.preventDefault();
        try {
            if (!role) {
                return toast.error(t('workflows.participants.selectRoleError'));
            }
            // don't allow to define Transaction Owner to another user
            if (role === WorkflowParticipantRoleEnum.TRANSACTION_OWNER) {
                return toast.error(t('errors.cannotSubmitOwnerRole'));
            }
            if (type === ParticipantType.INTERNAL) {
                if (!memberUserId) return;
            
                await onMemberSubmit({ userId: memberUserId, participantRole: role });
                setIsSubmitting(false);
                return;
            }

            await onSubmit({
                name,
                email,
                participantRole: role,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const getRoleOptions = async () => {
        const [rolesOptions] = await getParticipantRoleOptions(workflowType);
        if (rolesOptions) {
            const rolesTransformedOptions = rolesOptions
                .map((option) => ({
                    value: option.participantRole,
                    label: t(`participantRole.${option.participantRole}`),
                })).sort((a, b) => a.label.localeCompare(b.label));

            const placeholderOption = { value: '', label: t('workflows.participants.chooseFunction'), disabled: true };
            setRoleOptions([placeholderOption, ...rolesTransformedOptions]);
        }
    };

    const handleChangeTab = (_: SyntheticEvent, newValue: ParticipantType) => {
        setType(newValue);
        setRole(null);
    };

    const handleSearch = async () => {
        setIsSearching(true);
        const [userResponse, error] = await getUserByEmail(email?.toString().trim());
        setIsSearching(false);
    
        if (userResponse?.status === 200) {
            setExternalParticipant(userResponse?.data);
            setParticipantStatus(userResponse?.status);
            setName(userResponse?.data.fullName);
        } else if (userResponse?.status === 204) {
            setParticipantStatus(userResponse?.status);
            setExternalParticipant(null);
        }
        if (error) {
            toast.error(t('errors.findUserByEmail'));
        }
    };

    const handleButtonClick = () => {
        if (externalStep === 0) {
            if (participantStatus === 0) {
                handleSearch();
            } else {
                setExternalStep(1);
            }
        }
    };

    const handleButtonDisabled = () => {
        if (externalStep === 0) {
            return !isValidEmail;
        }
        
        return false;
    };
    const handleButtonLabel = () => {
        if (externalStep === 0) {
            return participantStatus === 0
                ? t('workflows.participants.search')
                : t('workflows.participants.next');
        }
    };

    const handleStepBack = () => {
        setExternalStep(0);
        setEmail('');
        setExternalParticipant(null);
        setParticipantStatus(0);
    };

    return (
        <Modal
            open={open}
            title={t('workflows.participants.addParticipant')}
            handleClose={onModalClose}
            extraClasses="add-participant-modal"
        >
            <TabContext value={type}>
                <Box>
                    <TabList onChange={handleChangeTab} data-testid="tab-list">
                        <Tab label={t('workflows.participants.external')} value={ParticipantType.EXTERNAL} />
                        {organizationSelected?.organization?.id && (
                            <Tab label={t('workflows.participants.internal')} value={ParticipantType.INTERNAL} />
                        )}
                    </TabList>
                </Box>
    
                <TabPanel value={ParticipantType.EXTERNAL}>
                    {externalStep === 1 && (
                        <div className="signer-form form__fields">
                            <Button
                                extraClasses="back-button"
                                startIcon={<BackIcon />}
                                onClick={handleStepBack}
                            >
                                {t('contractCreate.searchOtherSigner')}
                            </Button>
                        </div>
                    )}
                    <form className="form" data-testid="add-participant-form" onSubmit={handleSubmit}>
                        <FormField
                            name="email"
                            label={t('workflows.participants.labelEmail')}
                            value={email}
                            onChange={(_, value) => setEmail(value)}
                            fullWidth
                        />
                        {isSearching && <LoadingCircles size="xs" variant="secondary" />}
                        {externalStep === 0 && (
                            <>
                                {participantStatus === 200 && externalParticipant && (
                                    <UserCard user={externalParticipant} setSignerStep={() => setExternalStep(1)} />
                                )}
                                {participantStatus === 204 && (
                                    <Alert
                                        variant="info"
                                        icon={<UserProfileIcon />}
                                        message={t('contractCreate.signerNotFound')}
                                    />
                                )}
                                <Button
                                    onClick={handleButtonClick}
                                    variant={ButtonVariant.Curved}
                                    extraClasses="full-width"
                                    testId="search-button"
                                    disabled={handleButtonDisabled()}
                                >
                                    {handleButtonLabel()}
                                </Button>
                            </>
                        )}
                        { externalStep === 1 && (
                            <>
                                <hr className="divider" />
                                <FormField
                                    name="name"
                                    maxLength={250}
                                    label={t('workflows.participants.labelName')}
                                    value={name}
                                    onChange={(_, value) => setName(value)}
                                    fullWidth
                                />
                                <FormSelectField
                                    name="role"
                                    label={t('workflows.participants.labelRole')}
                                    options={roleOptions}
                                    onChange={(_, value) => setRole(value as WorkflowParticipantRoleEnum | null)}
                                    fullWidth
                                />

                                <Button
                                    isSubmit
                                    variant={ButtonVariant.Curved}
                                    extraClasses="full-width"
                                    testId="submit-btn"
                                    disabled={isSubmitting || !name || !role || !email}
                                >
                                    {t('workflows.participants.addParticipant')}
                                </Button>
                            </>
                        )}
                        
                    </form>
                </TabPanel>
                <TabPanel value={ParticipantType.INTERNAL}>
                    <form
                        className="form"
                        onSubmit={handleSubmit}
                    >
                        <RadioGroupWithSearch
                            name="member"
                            label={t('workflows.participants.internal')}
                            options={memberOptions}
                            searchPlaceholder={t('workflows.participants.placeholderMemberSearch')}
                            value={memberUserId}
                            onChange={(_, id) => setMemberUserId(String(id))}
                            onSearch={filterOrganizationMembers}
                            isLoading={isLoadingMembers}
                        />
                        <FormSelectField
                            name="role"
                            label={t('workflows.participants.labelRole')}
                            options={roleOptions}
                            onChange={(_, value) => setRole(value as WorkflowParticipantRoleEnum)}
                            fullWidth
                        />
                        <Button
                            isSubmit
                            variant={ButtonVariant.Curved}
                            extraClasses="primary"
                            testId="submit-btn"
                            disabled={isSubmitting || !memberUserId || !role}
                        >
                            {t('workflows.participants.addParticipant')}
                        </Button>
                    </form>
                </TabPanel>
            </TabContext>
          
        </Modal>
    );
};

export const AddParticipantModal = withUserContext(withWorkflowContext(withTranslationContext(withOrganizationsContext(AddParticipantModalComponent))));
