/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Avatar,
    AvatarGroup,
    Tooltip,
} from '@mui/material';
import classNames from 'classnames';
import { FunctionComponent, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronDown } from '../../../assets/images/arrow-down-broken.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkmark-green.svg';
import { ReactComponent as ChevronUp } from '../../../assets/images/chevron-up.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock.svg';
import { ReactComponent as CrossIcon } from '../../../assets/images/cross-red.svg';
import { ReactComponent as DraftIcon } from '../../../assets/images/document-dash.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/images/document-icon.svg';
import { ReactComponent as PenIcon } from '../../../assets/images/pen.svg';
import { ReactComponent as OwnerIcon } from '../../../assets/images/profile.svg';
import { ReactComponent as DangerIcon } from '../../../assets/images/danger.svg';
import { AppRoute } from '../../../constants/routes';
import { Contract, ContractState, Signer } from '../../../types/contracts';
import { getInitialsFromFirstAndLastName } from '../../../utils/misc';
import { buildUrl } from '../../../utils/navigation';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { ButtonDropdown } from '../ButtonDropdown';
import { DeleteContractOption } from './options/DeleteContractOption';
import TextBadge from '../TextBadge';
import { TextBadgeColor } from '../../../types/textBadge';
import { getTimeLeft } from '../../../utils/date';

interface OwnProps extends TranslationContext {
    contract: Contract;
}

const ContractCard: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        contract,
    } = props;

    const { user } = useAuthContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const signingMode = contract.contractState === ContractState.SIGNING;
    const navigate = useNavigate();

    const numberOfSignatures = useMemo(() => {
        const signers = contract.signers ?? [];

        let signed = 0;
        signers.forEach((signer) => {
            if (signer.signedAt) {
                signed++;
            }
        });

        return signed;
    }, [contract.signers]);

    const signerIsCurrentUser = () => {
        return contract.signers?.some((signer) => signer.signerUserId === user?.id && !signer.signedAt);
    };

    const getOpenContractUrl = () => {
        switch (contract.contractState) {
            case ContractState.DRAFT:
                return buildUrl(AppRoute.DraftContract, { contractId: String(contract.id) });
            case ContractState.SIGNING:
            case ContractState.SIGNED:
            case ContractState.VOIDED:
            default:
                return buildUrl(AppRoute.ShowContract, { contractId: String(contract.id) });
        }
    };

    const renderContractStatusIcon = () => {
        switch (contract.contractState) {
            case ContractState.DRAFT:
                return <DraftIcon />;
            case ContractState.SIGNING:
                return <ClockIcon />;
            case ContractState.SIGNED:
                return <CheckIcon />;
            case ContractState.VOIDED:
            default:
                return <CrossIcon />;
        }
    };

    const getContractStatusBadgeColor = () => {
        switch (contract.contractState) {
            case ContractState.SIGNING:
                return signerIsCurrentUser() ? TextBadgeColor.Yellow : TextBadgeColor.Purple;
            case ContractState.SIGNED:
                return TextBadgeColor.Green;
            case ContractState.VOIDED:
                return TextBadgeColor.Red;
            case ContractState.DRAFT:
            default:
                return TextBadgeColor.Gray;
        }
    };

    const handleShowContract = () => {
        const contractUrl = buildUrl(AppRoute.ShowContract, { contractId: String(contract.id) });
        navigate(contractUrl);
    };
    
    const handleEditContract = () => {
        const contractUrl = buildUrl(AppRoute.DraftContract, { contractId: String(contract.id) });
        navigate(contractUrl);
    };

    const handleOpenDeleteModal = () => {
        setIsOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setIsOpenDeleteModal(false);
    };

    const actions = useMemo(() => {
        const conditions: { check: boolean; label: string; id: number; action: () => void }[] = [
            {
                check: signerIsCurrentUser() && signingMode,
                label: t('contractActions.sign'),
                id: 1,
                action: handleShowContract,
            },
            {
                check: [ContractState.SIGNING, ContractState.SIGNED, ContractState.VOIDED].includes(contract.contractState),
                label: t('contractActions.open'),
                id: 2,
                action: handleShowContract,
            },
            {
                check: contract.contractState === ContractState.DRAFT,
                label: t('contractActions.edit'),
                id: 4,
                action: handleEditContract,
            },
            {
                check: contract.contractState === ContractState.DRAFT,
                label: t('contractActions.remove'),
                id: 5,
                action: handleOpenDeleteModal,
            },
        ];
    
        return conditions
            .filter((condition) => condition.check)
            .map(({ id, label, action }) => ({ id, label, action }));
    }, [contract.contractState]);
      
    const renderSignerState = (signer: Signer) => {
        if (!signer.signerUserId) {
            const { days, hours, minutes } = getTimeLeft(signer.inviteExpiresAt);
            
            if (days > 0 && hours === 0) {
                return (
                    <div className="contract-card__valid">
                        <span>
                            {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {days} {t(days === 1 ? 'date.day' : 'date.days')}
                        </span>
                    </div>
                    
                );
            }
            
            if (days > 0 && hours > 0) {
                return (
                    <div className="contract-card__valid">
                        <span>
                            {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {days} {t(days === 1 ? 'date.day' : 'date.days')} {hours} {t(hours === 1 ? 'date.hour' : 'date.hours')}
                        </span>
                    </div>
                    
                );
            }
            
            if (days === 0 && hours > 1 && minutes === 0) {
                return (
                    <div className="contract-card__valid">
                        <span>
                            {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {hours} {t('date.hours')}
                        </span>
                    </div>
                );
            }
            
            if (days === 0 && hours > 0 && minutes > 0) {
                return (
                    <div className="contract-card__valid">
                        <span>
                            {t('signerStates.inviteSend')} | {t('signerStates.validFor')} {hours} {t(hours === 1 ? 'date.hour' : 'date.hours')} {minutes} {t(minutes === 1 ? 'date.minute' : 'date.minutes')}
                        </span>
                    </div>
                   
                );
            }
            
            if (days === 0 && hours === 1 && minutes === 0) {
                return (
                    <div className="contract-card__alert">
                        <DangerIcon />
                        <span>{t('signerStates.inviteValid')} {hours} {t('date.hour')}</span>
                    </div>
                );
            }
            
            if (days === 0 && hours < 1 && minutes > 0) {
                return (
                    <div className="contract-card__alert">
                        <DangerIcon />
                        <span>{t('signerStates.inviteValid')} {minutes} {t(minutes === 1 ? 'date.minute' : 'date.minutes')}</span>
                    </div>
                );
            }
            
            return (
                <div className="contract-card__alert">
                    <DangerIcon />
                    <span>{t('signerStates.inviteExpired')}</span>
                </div>
            );
        }
    
        if (signer.signedAt) {
            return (
                <div className="contract-card__valid">
                    <span>
                        {t('signerStates.signed')}
                    </span>
                </div>
               
            );
        }
    
        return (
            <div className="contract-card__valid">
                <span>
                    {t('signerStates.notSigned')}
                </span>
            </div>
            
        );
    };

    return (
        <div className="contract-card card" data-testid="card">
            <Link to={getOpenContractUrl()} key={`link-${contract.id}`}>
                <div className="contract-card__header">
                    <div className="contract-card__header__info">
                        
                        <Avatar alt="owner avatar" sizes="14">
                            <OwnerIcon />
                        </Avatar>
                        <h2 className="contract-card__header__info__contract-owner">
                            {contract.userOwner.fullName}
                        </h2>
                    </div>
                    <div className="contract-card__header__chips">
                        { contract.externalId
                            && (
                                <div className="ontract-card__header__chip">
                                    <TextBadge color={TextBadgeColor.Gray} text={`# ${contract.externalId}`} />
                                </div>
                            )
                        }
                        <div className="contract-card__signatures-wrapper__general-info__current-signer-state contract-card__signatures-wrapper__general-info__current-signer-state">
                            <TextBadge
                                color={getContractStatusBadgeColor()}
                                icon={renderContractStatusIcon()}
                                text={signerIsCurrentUser() && signingMode ? t('contracts.requiresYourSignature') : t(`contractStates.${contract.contractState}`)}
                            />
                        </div>
                    </div>
                </div>
                <h2 className="contract-card__contract-type">{contract.contractType.name}</h2>
                <div className="contract-card__contract-name">
                    <DocumentIcon />
                    <h3>
                        {contract.name}
                    </h3>
                </div>
            </Link>
            <div className="contract-card__signatures-wrapper">
                <div className="contract-card__signatures-wrapper__general-info">
                    <ButtonDropdown
                        options={actions}
                        onAction={(selectedAction) => selectedAction.action()}
                    />
                    <div className="contract-card__signatures-wrapper__general-info__signing-state">
                        <div className="contract-card__signatures-wrapper__general-info__signing-state__signers">
                            <AvatarGroup max={3} spacing={0}>
                                {contract.signers?.map((signer) => (
                                    <div
                                        key={`${contract.id}-${signer.id}`}
                                        className="contract-card__signatures-wrapper__general-info__signing-state__signers__signer"
                                    >
                                        <Tooltip title={signer.name} placement="top">
                                            <Avatar>
                                                {getInitialsFromFirstAndLastName(signer.name)}
                                            </Avatar>
                                        </Tooltip>
                                        {signer.signedAt ? (
                                            <CheckIcon className="contract-card__signatures-wrapper__general-info__signing-state__signers__signer__signed" />
                                        ) : (
                                            <ClockIcon className="contract-card__signatures-wrapper__general-info__signing-state__signers__signer__pending" />
                                        )}
                                    </div>
                                ))}
                            </AvatarGroup>
                        </div>
                        {contract.signers?.length > 0 && (
                            <div>
                                <div className={classNames('contract-card__signatures-wrapper__general-info__signing-state__contract-state',
                                    { 'contract-card__signatures-wrapper__general-info__signing-state__contract-state--all-signed': contract.signers.length === numberOfSignatures })}
                                >
                                    <PenIcon />{numberOfSignatures}/{contract.signers?.length ?? 0}
                                </div>
                                <button
                                    type="button"
                                    className="contract-card__signatures-wrapper__general-info__signing-state__expand-signers"
                                    onClick={() => setIsExpanded((prev) => !prev)}
                                    data-testid="expand-signers-button"
                                >
                                    {isExpanded ? (
                                        <ChevronUp />
                                    ) : (
                                        <ChevronDown />
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {isExpanded && contract.signers.length > 0 && (
                    <div className="contract-card__signatures-wrapper__signers-list" data-testid="signers-list">
                        {contract.signers.map((signer) => (
                            <div key={signer.id}>
                                <div className="contract-card__signatures-wrapper__signers-list__signer">
                                
                                    <div className="contract-card__signatures-wrapper__signers-list__signer__avatar">
                                        <Avatar>{getInitialsFromFirstAndLastName(signer.name)}</Avatar>
                                        {signer.signedAt ? <CheckIcon className="contract-card__signatures-wrapper__signers-list__signer__avatar__signed" /> : <ClockIcon className="contract-card__signatures-wrapper__signers-list__signer__avatar__pending" />}
                                    </div>
                                    <div>
                                        <div className="contract-card__signatures-wrapper__signers-list__signer__name">
                                            {signer.name}
                                        </div>
                                        <div className="contract-card__signatures-wrapper__signers-list__signer__signature">
                                            {`${t('contracts.signersSignature')} ${t(`signatureOptions.${signer.signatureType}`)}`}
                                        </div>
                                        <div data-testid="signer-status">{renderSignerState(signer)}</div>
                                    </div>
                                </div>
                               
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {contract.contractState === ContractState.DRAFT && (
                <DeleteContractOption isOpen={isOpenDeleteModal} hideDeleteButton onModalClose={handleCloseDeleteModal} contractParam={contract} />
            )}
        </div>
    );
};
 
export default withTranslationContext(ContractCard);
