/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FormControl, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import { without } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as PeopleIcon } from '../../../../assets/images/people.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/images/profile.svg';
import { ButtonVariant } from '../../../../types/general';
import {
    DocumentAccessPermissionsType,
    ResourcePermissionsType,
    ResourceType,
    WorkflowDocument,
    WorkflowResourceRequest,
    WorkflowResourceRequestStatus,
} from '../../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../../controllers/WorkflowContext';
import Button from '../../Button';
import Modal from '../../Modal';
import { Tooltip } from '../../Tooltip';

interface OwnProps extends TranslationContext, WorkflowContext {
    testId?: string;
    document: WorkflowDocument;
}

const WorkflowDocumentPermissionsOptionComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        document: resource,
        testId,
        getResourceRequests,
        updateResourceRequestPermission,
        reviewResourceRequest,
    } = props;

    const [searchParams] = useSearchParams();
    const accessRequestAndPermissions = searchParams.get('accessRequestAndPermissions') ?? '';
    
    const [isOpenModal, setIsOpenModal] = useState(!!accessRequestAndPermissions);
    const [workflowResourceRequests, setWorkflowResourceRequests] = useState<WorkflowResourceRequest[]>([]);
    const [pendingResourceRequests, setPendingResourceRequests] = useState<WorkflowResourceRequest[]>([]);
    const [loadingParticipantPermissionIds, setLoadingParticipantPermissionIds] = useState<number[]>([]);
    
    useEffect(() => {
        init();
    }, []);

    const init = () => {
        getResourceRequests(String(resource.transactionId), String(resource.id), ResourceType.DOCUMENT, WorkflowResourceRequestStatus.ACCEPTED)
            .then((resourceAccessResponse) => {
                const [resourceAccess] = resourceAccessResponse;

                if (resourceAccess) {
                    setWorkflowResourceRequests(resourceAccess);
                }
            });

        getResourceRequests(String(resource.transactionId), String(resource.id), ResourceType.DOCUMENT, WorkflowResourceRequestStatus.PENDING)
            .then((resourceAccessResponse) => {
                const [resourceAccess] = resourceAccessResponse;

                if (resourceAccess) {
                    setPendingResourceRequests(resourceAccess);
                }
            });
    };

    const handleReview = (resourceAssetRequest: WorkflowResourceRequest, status: WorkflowResourceRequestStatus) => {
        reviewResourceRequest(String(resourceAssetRequest.transactionId), String(resourceAssetRequest.requestToken), status)
            .then((res) => {
                if (!res) {
                    toast.success(t('workflows.documents.accessGranted'));
                    init();
                }
            });
    };

    const onOpenConfirmationModal = () => {
        setIsOpenModal(true);
    };

    const onCancel = () => {
        setIsOpenModal(false);
    };

    const handleUpdateRequestAccess = async (resourceRequestAccess: WorkflowResourceRequest, permissionType: ResourcePermissionsType) => {
        setLoadingParticipantPermissionIds((prev) => [...prev, resourceRequestAccess.id]);

        await updateResourceRequestPermission(String(resource.transactionId), String(resource.id), ResourceType.DOCUMENT, resourceRequestAccess.participantId, permissionType)
            .then((resourceUpdateError) => {
                if (!resourceUpdateError) {
                    toast.success(t('workflows.participants.permissions.permissionUpdateSuccess'));

                    setWorkflowResourceRequests((prev) => prev.map((ra) => {
                        if (ra.id === resourceRequestAccess.id) {
                            return {
                                ...resourceRequestAccess,
                                permissionType,
                            };
                        }

                        return ra;
                    }));
                } else {
                    toast.error(t('workflows.participants.permissions.permissionUpdateFailed'));
                }
            });

        setLoadingParticipantPermissionIds((prev) => {
            return without(prev, resourceRequestAccess.id);
        });
    };

    const renderParticipantPermission = (requestAccess: WorkflowResourceRequest) => {
        return (
            <div key={requestAccess.id} className="participant-permission participant-card">
                <div className="participant-card__info">
                    <span className="participant-card__info__icon-wrap">
                        <ProfileIcon />
                    </span>
                    <p>{requestAccess.participantName}</p>
                </div>
                <FormControl
                    variant="standard"
                    disabled={loadingParticipantPermissionIds.some((e) => e === requestAccess.id)}
                >
                    <Select
                        value={requestAccess.permissionType}
                        name={`${requestAccess.participantId}-permission`}
                        onChange={(e) => handleUpdateRequestAccess(requestAccess, e.target.value as ResourcePermissionsType)}
                    >
                        {Object.values(DocumentAccessPermissionsType).map((p) => (
                            <MenuItem className="menu-item" key={p} value={p}>
                                {t(`workflows.participants.permissions.${p}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    };

    const renderPendingRequests = () => {
        return (
            <>
                {pendingResourceRequests.map((r) => (
                    <div key={r.id} className="pending-permission-card">
                        <div className="pending-permission-card__header">
                            <span className="pending-permission-card__header__icon-wrap">
                                <ProfileIcon />
                            </span>
                            <p>{r.participantName}</p>
                        </div>
                        <div className="pending-permission-card__body">
                            <p>{t('workflows.participants.requestingAccess', { name: r.participantName, documentName: resource.name })}</p>
                        </div>
                        <div className="pending-permission-card__buttons">
                            <Button
                                variant={ButtonVariant.Curved}
                                extraClasses="secondary"
                                onClick={() => handleReview(r, WorkflowResourceRequestStatus.REJECTED)}
                            >
                                {t('workflows.participants.rejectAction')}
                            </Button>
                            <Button
                                variant={ButtonVariant.Curved}
                                extraClasses="primary"
                                onClick={() => handleReview(r, WorkflowResourceRequestStatus.ACCEPTED)}
                            >
                                {t('workflows.participants.acceptAction')}
                            </Button>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <>
            <Button
                variant={ButtonVariant.IconBtn}
                extraClasses={classNames(
                    'primary',
                    { active: isOpenModal },
                )}
                onClick={onOpenConfirmationModal}
                testId={testId}
            >
                <Tooltip title={t('workflows.documents.managePermissions')} placement="left">
                    <PeopleIcon />
                </Tooltip>
            </Button>
            <Modal
                open={isOpenModal}
                title={t('workflows.documents.managePermissions')}
                handleClose={onCancel}
            >
                <div className="content-wrap participant-permissions-body">
                    <h2>{t('workflows.participants.requests')}</h2>
                    {renderPendingRequests()}
                    {pendingResourceRequests.length === 0 && (<p>{t('workflows.documents.noAccessesToManage')}</p>)}
                    
                    <h2 className="participant-permissions-body__permissions-title">{t('workflows.participants.permissions.title')}</h2>
                    <div className="participant-card-list">
                        {workflowResourceRequests.map((r) => {
                            return renderParticipantPermission(r);
                        })}
                    </div>
                    {workflowResourceRequests.length === 0 && (<p>{t('workflows.documents.noPermissionsToManage')}</p>)}
                </div>
            </Modal>
        </>
    );
};

export const WorkflowDocumentPermissionsOption = withWorkflowContext(withTranslationContext(WorkflowDocumentPermissionsOptionComponent));
