/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from './general';

type ErrorCodeType = ErrorCode | string | number;

export enum ErrorCode {
    GENERAL = '0',
    FORBIDDEN = '1',
    BAD_REQUEST_API = '2',
    ENTITY_NOT_FOUND = '3',
    COULD_NOT_STORE_FILE = '4',
    ROLE_NAME_ALREADY_EXISTS = '5',
    COULD_NOT_CREATE_USER = '6',
    USERNAME_ALREADY_TAKEN = '7',
    COULD_NOT_GET_FILE = '8',
    ORGANIZATION_ACCESS_ALREADY_EXISTS = '9',
    INVALID_ORGANIZATION_ACCESS_INTERVAL = '10',
    INVALID_ORGANIZATION_ACCESS_ROLE = '11',
    SIGNER_INVITE_IS_ALREADY_ANSWERED = '12',
    SIGNER_INVITE_HAS_EXPIRED = '13',
    SIGNER_IS_ALREADY_READY_TO_SIGN = '14',
    CONTRACT_IS_NOT_IN_DRAFT_STATE = '15',
    CONTRACT_HAS_NO_SIGNERS = '16',
    NOT_ALL_SIGNERS_ARE_READY_TO_SIGN_CONTRACT = '17',
    SIGNATURE_NOT_AVAILABLE = '18',
    RESOURCE_FILE_NOT_FOUND = '19',
    USER_ALREADY_HAS_ACCESS_TO_RESOURCE = '20',
    INVALID_RESOURCE_ACCESS_EXPIRATION_DATE = '21',
    NO_ACTIVE_RESOURCE_PERMISSION_FOR_USER = '22',
    NO_EDIT_PERMISSIONS_FOR_CONTRACT_ACCESS = '23',
    USER_IS_NOT_CONTRACT_OWNER = '24',
    USER_IS_MISSING_REQUIRED_FIELDS = '25',
    CONTRACT_TYPE_ALREADY_EXISTS = '26',
    CANNOT_DELETE_CONTRACT_TYPE_IN_USE = '27',
    INVALID_CURSOR = '28',
    INVALID_INVITE_EXPIRATION_DATE = '29',
    ORGANIZATION_INVITE_HAS_EXPIRED = '30',
    ORGANIZATION_INVITE_IS_ALREADY_ANSWERED = '31',
    ORGANIZATION_INVITE_USER_IS_INVALID = '32',
    ROLE_NOT_DELETABLE = '33',
    ROLE_IN_USE = '34',
    ROLE_PERMISSIONS_CANNOT_BE_EDITED = '35',
    COULD_NOT_DELETE_FILE = '36',
    PLACEHOLDER_HAS_COLLISIONS = '37',
    CANNOT_CREATE_UPDATE_PLACEHOLDER = '38',
    CANNOT_READ_UPLOADED_FILE = '39',
    SIGNER_USER_ALREADY_ASSIGNED = '40',
    INVALID_SIGNER_ASSIGNMENT_BY_EMAIL = '41',
    INVALID_SIGNER_ASSIGNMENT_BY_PIN = '42',
    SIGNER_INVITE_EMAIL_ALREADY_TAKEN = '43',
    USER_IS_ALREADY_CONTRACT_SIGNER = '44',
    SCMD_INVALID_PARAMETERS = '45',
    SCMD_INVALID_PIN = '46',
    INVALID_SIGNATURE_OTP = '47',
    SCMD_SERVICE_UNAVAILABLE = '48',
    SCMD_SERVICE_ERROR = '49',
    SCMD_SERVICE_GENERIC_ERROR = '50',
    SCMD_REQUEST_ALREADY_IN_PLACE = '51',
    DSS_UNABLE_TO_CALCULATE_SIGNATURE_DIGEST = '52',
    DSS_INVALID_SIGNATURE_PARAMETERS ='53',
    SCMD_NO_VALID_REQUEST_EXISTS = '54',
    SCMD_SIGNATURE_ALREADY_EXISTS = '55',
    DSS_SERVICE_UNAVAILABLE = '56',
    AES_REQUEST_SIGNATURE_FAILED = '57',
    AES_FETCH_SIGNATURE_DETAILS_FAILED = '58',
    AES_USER_SIGNATURE_CERTIFICATE_NOT_FOUND = '59',
    AES_REQUEST_ALREADY_IN_PLACE = '60',
    AES_SERVICE_GENERIC_ERROR = '61',
    AES_SERVICE_FAILED_ERROR = '62',
    AES_SIGNATURE_ALREADY_COMPLETED = '63',
    AES_NO_VALID_REQUEST_EXISTS = '64',
    AES_OTP_SERVICE_ERROR = '65',
    EID_DOCUMENTATION_COULD_NOT_BE_RETRIEVED = '66',
    SERVICE_TERMS_NOT_ACCEPTED = '67',
    QES_VIDEO_SERVICE_UNAVAILABLE = '68',
    SIGNER_ALREADY_SIGNED_DOCUMENT = '69',
    NO_ACTIVE_SIGNATURE_IN_PLACE = '70',
    INCOMPLETE_SIGNATURE_STEPS = '71',
    SIGNING_SESSION_IN_PROGRESS = '72',
    CANNOT_CONVERT_TO_PDFA = '73',
    QES_VIDEO_VERIFICATION_REJECTED = '74',
    USER_IS_NOT_TRANSACTION_OWNER = '75',
    USER_ALREADY_HAS_ACCESS_TO_TRANSACTION = '76',
    INVALID_TRANSACTION_ACCESS_EXPIRATION_DATE = '77',
    PARTICIPANT_INVITE_EMAIL_ALREADY_TAKEN = '78',
    PARTICIPANT_USER_ALREADY_ASSIGNED = '79',
    USER_IS_ALREADY_TRANSACTION_PARTICIPANT = '80',
    INVALID_PARTICIPANT_ASSIGNMENT_BY_EMAIL = '81',
    INVALID_PARTICIPANT_ASSIGNMENT_BY_PIN = '82',
    SIGNER_USER_IS_NOT_ASSIGNED = '83',
    UNABLE_TO_LOAD_AVAILABILITY_LIST = '84',
    ENCODING_STRING_FAILED = '85',
    DECODING_STRING_FAILED = '86',
    DOCUMENT_STATUS_NEEDS_NOTES = '87',
    DOCUMENT_TYPE_ALREADY_EXISTS = '88',
    DOCUMENT_ALREADY_REVIEWED = '89',
    USER_IS_NOT_TRANSACTION_PARTICIPANT = '90',
    INVALID_TRANSACTION_RESOURCE = '91',
    ACTIVE_TRANSACTION_RESOURCE_REQUEST_IN_PLACE = '92',
    INVALID_TRANSACTION_RESOURCE_REQUEST = '93',
    TRANSACTION_RESOURCE_REQUEST_ALREADY_REVIEWED = '94',
    TRANSACTION_RESOURCE_REQUEST_EXPIRED = '95',
    INVALID_CURRENCY = '96',
    COMMISSION_NOT_DEFINED = '97',
    PARTICIPANT_USER_NOT_DEFINED = '98',
    CANNOT_TRANSFER_THE_OWNERSHIP_TO_THE_SAME_USER = '99',
    CANNOT_KEEP_STATE_WITHOUT_NOTES = '100',
    WALLET_ALREADY_EXISTS = '101',
    USER_IS_TRANSACTION_OWNER = '102',
    SCMD_NOT_CONFIGURED = '103',
    SUBSCRIPTION_PLAN_CREATION_DUPLICATED_FEATURES = '104',
    SUBSCRIPTION_PLAN_CREATION_MISSING_QUOTA = '105',
    SUBSCRIPTION_PLAN_CREATION_MISSING_UNIT_PRICE = '106',
    MISSING_MONEY_FIELDS = '107',
    STRIPE_INVALID_WEBHOOK = '108',
    WALLET_INSUFFICIENT_CREDITS = '109',
    SUBSCRIPTION_PLAN_IS_NOT_ACTIVE = '110',
    WALLET_INVALID_ACTION = '111',
    COMMISSION_TYPE_NOT_ALLOWED_IN_TRANSACTION = '112',
    USER_HAS_NOT_ACCESS_TO_ORGANIZATION_WALLET = '113',
    INVALID_ORDER_ITEMS = '114',
    STRIPE_UNABLE_TO_PROCEED_WITH_PAYMENT_INTENT = '115',
    PERSONAL_WALLET_CANNOT_HAVE_SUBSCRIPTION = '116',
    PAYMENT_INTENT_NEEDS_TO_HAVE_AT_LEAST_CREDITS_OR_SUBSCRIPTION_PLAN = '117',
    CONTRACT_CANNOT_BE_REPLACED = '118',
    INVALID_RESOURCE_ACCESS = '119',
    MISSING_SUBSCRIPTION = '120',
    FEATURE_NOT_SUBSCRIBED = '122',
    FEATURE_QUOTA_ENDED = '123',
    FEATURE_IS_PAID = '124',
    WALLET_DOESNT_MATCH_WITH_BASKET = '125',
    WALLET_DOESNT_MATCH_WITH_USER = '126',
    STRIPE_PAYMENT_FAILED = '127',
    STRIPE_DEFAULT_PAYMENT_METHOD_NOT_CONFIGURED = '128',
    WALLET_ACCESS_DENIED_ORGANIZATION = '129',
    WALLET_ACCESS_DENIED_PERSONAL = '130',
    FEATURE_TYPE_SIGNATURE_MAPPER_NOT_IMPLEMENTED = '131',
    PURCHASE_ITEM_RESOURCE_TYPE_NOT_IMPLEMENTED = '132',
    MISSING_WALLET_FOR_PURCHASE_PAYMENT = '133',
    MISSING_ASSET_ID_FIELD = '134',
    WALLET_HAS_ACTIVE_SUBSCRIPTION = '135',
    INVALID_FEATURE_TYPE = '136',
    SUBSCRIPTION_ALREADY_CANCELED = '137',
    CONTRACT_IS_VOIDED = '138',
    UNAUTHORIZED = 'UNAUTHORIZED',
    BAD_REQUEST = 'BAD_REQUEST',
    ERR_NETWORK = 'ERR_NETWORK',
    ERR_CANCELED = 'ERR_CANCELED',
    TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE',
}

export const ErrorCodeMap: KeyedObject<string> = {
    [ErrorCode.GENERAL]: 'general',
    [ErrorCode.FORBIDDEN]: 'forbidden',
    [ErrorCode.BAD_REQUEST_API]: 'badRequest',
    [ErrorCode.ENTITY_NOT_FOUND]: 'entityNotFound',
    [ErrorCode.COULD_NOT_STORE_FILE]: 'couldNotStoreFile',
    [ErrorCode.ROLE_NAME_ALREADY_EXISTS]: 'roleNameAlreadyExists',
    [ErrorCode.COULD_NOT_CREATE_USER]: 'couldNotCreateUser',
    [ErrorCode.USERNAME_ALREADY_TAKEN]: 'usernameAlreadyTaken',
    [ErrorCode.COULD_NOT_GET_FILE]: 'couldNotGetFile',
    [ErrorCode.ORGANIZATION_ACCESS_ALREADY_EXISTS]: 'organizationAccessAlreadyExists',
    [ErrorCode.INVALID_ORGANIZATION_ACCESS_INTERVAL]: 'invalidOrganizationAccessInternal',
    [ErrorCode.INVALID_ORGANIZATION_ACCESS_ROLE]: 'invalidOrganizationAccessRole',
    [ErrorCode.SIGNER_INVITE_IS_ALREADY_ANSWERED]: 'signerInviteIsAlreadyAnswered',
    [ErrorCode.SIGNER_INVITE_HAS_EXPIRED]: 'expiredInvitation',
    [ErrorCode.SIGNER_IS_ALREADY_READY_TO_SIGN]: 'signerIsAlreadyReadyToSign',
    [ErrorCode.CONTRACT_IS_NOT_IN_DRAFT_STATE]: 'contractIsNotInDraftState',
    [ErrorCode.CONTRACT_HAS_NO_SIGNERS]: 'contractHasNoSigners',
    [ErrorCode.NOT_ALL_SIGNERS_ARE_READY_TO_SIGN_CONTRACT]: 'notAllSignersAreReadyToSignContract',
    [ErrorCode.SIGNATURE_NOT_AVAILABLE]: 'signatureNotAvailable',
    [ErrorCode.RESOURCE_FILE_NOT_FOUND]: 'resourceFileNotFound',
    [ErrorCode.USER_ALREADY_HAS_ACCESS_TO_RESOURCE]: 'userAlreadyHasAccessToResource',
    [ErrorCode.INVALID_CURSOR]: 'invalidCursor',
    [ErrorCode.INVALID_RESOURCE_ACCESS_EXPIRATION_DATE]: 'invalidResourceAccessExpirationDate',
    [ErrorCode.NO_ACTIVE_RESOURCE_PERMISSION_FOR_USER]: 'noActiveResourcePermissionForUser',
    [ErrorCode.NO_EDIT_PERMISSIONS_FOR_CONTRACT_ACCESS]: 'noEditPermissionsForContractAccess',
    [ErrorCode.USER_IS_NOT_CONTRACT_OWNER]: 'userIsNotContractOwner',
    [ErrorCode.USER_IS_MISSING_REQUIRED_FIELDS]: 'userIsMissingRequiredFields',
    [ErrorCode.CONTRACT_TYPE_ALREADY_EXISTS]: 'contractTypeAlreadyExists',
    [ErrorCode.CANNOT_DELETE_CONTRACT_TYPE_IN_USE]: 'cannotDeleteContractTypeInUse',
    [ErrorCode.INVALID_INVITE_EXPIRATION_DATE]: 'invalidInviteExpirationDate',
    [ErrorCode.ORGANIZATION_INVITE_HAS_EXPIRED]: 'organizationInviteHasExpired',
    [ErrorCode.ORGANIZATION_INVITE_IS_ALREADY_ANSWERED]: 'organizationInviteIsAlreadyAnswered',
    [ErrorCode.ROLE_NOT_DELETABLE]: 'roleNotDeletable',
    [ErrorCode.ROLE_IN_USE]: 'roleInUse',
    [ErrorCode.COULD_NOT_DELETE_FILE]: 'couldNotDeleteFile',
    [ErrorCode.PLACEHOLDER_HAS_COLLISIONS]: 'placeholderCollisions',
    [ErrorCode.CANNOT_CREATE_UPDATE_PLACEHOLDER]: 'cannotCreateUpdatePlaceholder',
    [ErrorCode.CANNOT_READ_UPLOADED_FILE]: 'cannotReadUploadedFile',
    [ErrorCode.SIGNER_USER_ALREADY_ASSIGNED]: 'signerUserAlreadyAssigned',
    [ErrorCode.INVALID_SIGNER_ASSIGNMENT_BY_EMAIL]: 'invalidSignerAssignmentByEmail',
    [ErrorCode.INVALID_SIGNER_ASSIGNMENT_BY_PIN]: 'invalidSignerAssignmentByPin',
    [ErrorCode.SIGNER_INVITE_EMAIL_ALREADY_TAKEN]: 'signerInviteEmailAlreadyTaken',
    [ErrorCode.USER_IS_ALREADY_CONTRACT_SIGNER]: 'userIsAlreadyContractSigner',
    [ErrorCode.SCMD_INVALID_PARAMETERS]: 'scmdInvalidPin',
    [ErrorCode.SCMD_INVALID_PIN]: 'scmdInvalidPin',
    [ErrorCode.INVALID_SIGNATURE_OTP]: 'invalidSignatureOtp',
    [ErrorCode.SCMD_SERVICE_UNAVAILABLE]: 'scmdServiceUnavailable',
    [ErrorCode.SCMD_SERVICE_ERROR]: 'scmdServiceUnavailable',
    [ErrorCode.SCMD_SERVICE_GENERIC_ERROR]: 'scmdServiceUnavailable',
    [ErrorCode.SCMD_REQUEST_ALREADY_IN_PLACE]: 'scmdRequestAlreadyInPlace',
    [ErrorCode.DSS_UNABLE_TO_CALCULATE_SIGNATURE_DIGEST]: 'dssUnableToCalculateSignatureDigest',
    [ErrorCode.DSS_INVALID_SIGNATURE_PARAMETERS]: 'dssInvalidSignatureParameters',
    [ErrorCode.SCMD_NO_VALID_REQUEST_EXISTS]: 'scmdNoValidRequestExists',
    [ErrorCode.SCMD_SIGNATURE_ALREADY_EXISTS]: 'scmdSignatureAlreadyExists',
    [ErrorCode.DSS_SERVICE_UNAVAILABLE]: 'dssServiceUnavailable',
    [ErrorCode.ORGANIZATION_INVITE_USER_IS_INVALID]: 'organizationInviteUserIsInvalid',
    [ErrorCode.ROLE_PERMISSIONS_CANNOT_BE_EDITED]: 'rolePermissionsCannotBeEdited',
    [ErrorCode.AES_REQUEST_SIGNATURE_FAILED]: 'aesRequestSignatureFailed',
    [ErrorCode.AES_FETCH_SIGNATURE_DETAILS_FAILED]: 'aesFetchSignatureDetailsFailed',
    [ErrorCode.AES_USER_SIGNATURE_CERTIFICATE_NOT_FOUND]: 'aesUserSignatureCertificateNotFound',
    [ErrorCode.AES_REQUEST_ALREADY_IN_PLACE]: 'aesRequestAlreadyInPlace',
    [ErrorCode.AES_SERVICE_GENERIC_ERROR]: 'aesGenericError',
    [ErrorCode.AES_SERVICE_FAILED_ERROR]: 'aesGenericError',
    [ErrorCode.AES_SIGNATURE_ALREADY_COMPLETED]: 'aesSignatureAlreadyCompleted',
    [ErrorCode.AES_NO_VALID_REQUEST_EXISTS]: 'aesNoValidRequestExists',
    [ErrorCode.AES_OTP_SERVICE_ERROR]: 'aesOtpServiceError',
    [ErrorCode.EID_DOCUMENTATION_COULD_NOT_BE_RETRIEVED]: 'eidDocumentationCOuldNotBeRetrieved',
    [ErrorCode.SERVICE_TERMS_NOT_ACCEPTED]: 'serviceTermsNotAccepted',
    [ErrorCode.QES_VIDEO_SERVICE_UNAVAILABLE]: 'qesVideoServiceUnavailable',
    [ErrorCode.SIGNER_ALREADY_SIGNED_DOCUMENT]: 'signerAlreadySignedDocument',
    [ErrorCode.NO_ACTIVE_SIGNATURE_IN_PLACE]: 'noActiveSignatureInPlace',
    [ErrorCode.INCOMPLETE_SIGNATURE_STEPS]: 'incompleteSignatureSteps',
    [ErrorCode.SIGNING_SESSION_IN_PROGRESS]: 'signingSessionInProgress',
    [ErrorCode.CANNOT_CONVERT_TO_PDFA]: 'cannotConvertToPdfa',
    [ErrorCode.QES_VIDEO_VERIFICATION_REJECTED]: 'qesVideoVerificationRejected',
    [ErrorCode.USER_IS_NOT_TRANSACTION_OWNER]: 'userIsNotTransactionOwner',
    [ErrorCode.USER_ALREADY_HAS_ACCESS_TO_TRANSACTION]: 'userAlreadyHasAccessToTransaction',
    [ErrorCode.INVALID_TRANSACTION_ACCESS_EXPIRATION_DATE]: 'invalidTransactionAccessExpirationDate',
    [ErrorCode.PARTICIPANT_INVITE_EMAIL_ALREADY_TAKEN]: 'participantInviteEmailAlreadyTaken',
    [ErrorCode.PARTICIPANT_USER_ALREADY_ASSIGNED]: 'participantUserAlreadyAssigned',
    [ErrorCode.USER_IS_ALREADY_TRANSACTION_PARTICIPANT]: 'userIsAlreadyTransactionParticipant',
    [ErrorCode.INVALID_PARTICIPANT_ASSIGNMENT_BY_EMAIL]: 'invalidParticipantAssignmentByEmail',
    [ErrorCode.INVALID_PARTICIPANT_ASSIGNMENT_BY_PIN]: 'invalidparticipantAssignmentByPin',
    [ErrorCode.SIGNER_USER_IS_NOT_ASSIGNED]: 'signerUserIsNotAssigned',
    [ErrorCode.UNABLE_TO_LOAD_AVAILABILITY_LIST]: 'unableToLoadAvailabilityList',
    [ErrorCode.ENCODING_STRING_FAILED]: 'encodingStringFailed',
    [ErrorCode.DECODING_STRING_FAILED]: 'decodingStringFailed',
    [ErrorCode.DOCUMENT_STATUS_NEEDS_NOTES]: 'documentStatusNeedsNotes',
    [ErrorCode.DOCUMENT_TYPE_ALREADY_EXISTS]: 'documentTypeAlreadyExists',
    [ErrorCode.DOCUMENT_ALREADY_REVIEWED]: 'documentAlreadyReviewed',
    [ErrorCode.USER_IS_NOT_TRANSACTION_PARTICIPANT]: 'userisNotTransactionParticipant',
    [ErrorCode.INVALID_TRANSACTION_RESOURCE]: 'invalidTransactionResource',
    [ErrorCode.ACTIVE_TRANSACTION_RESOURCE_REQUEST_IN_PLACE]: 'activeTransactionResourceRequestInPlace',
    [ErrorCode.INVALID_TRANSACTION_RESOURCE_REQUEST]: 'invalidTransactionResourceRequest',
    [ErrorCode.TRANSACTION_RESOURCE_REQUEST_ALREADY_REVIEWED]: 'transactionResourceRequestAlreadyReviewed',
    [ErrorCode.TRANSACTION_RESOURCE_REQUEST_EXPIRED]: 'transactionResourceRequestExpired',
    [ErrorCode.INVALID_CURRENCY]: 'invalidCurrency',
    [ErrorCode.COMMISSION_NOT_DEFINED]: 'commissionNotDefined',
    [ErrorCode.PARTICIPANT_USER_NOT_DEFINED]: 'participantUserNotDefined',
    [ErrorCode.CANNOT_TRANSFER_THE_OWNERSHIP_TO_THE_SAME_USER]: 'cannotTransferTheOwnershipToTheSameUser',
    [ErrorCode.CANNOT_KEEP_STATE_WITHOUT_NOTES]: 'cannotKeepStateWithoutNotes',
    [ErrorCode.WALLET_ALREADY_EXISTS]: 'walletAlreadyExists',
    [ErrorCode.USER_IS_TRANSACTION_OWNER]: 'userIsTransactionOwner',
    [ErrorCode.SCMD_NOT_CONFIGURED]: 'scmdNotConfigured',
    [ErrorCode.SUBSCRIPTION_PLAN_CREATION_DUPLICATED_FEATURES]: 'subscriptionPlanCreationDuplicatedFeatures',
    [ErrorCode.SUBSCRIPTION_PLAN_CREATION_MISSING_QUOTA]: 'subscriptionPlanCreationMissingQuota',
    [ErrorCode.SUBSCRIPTION_PLAN_CREATION_MISSING_UNIT_PRICE]: 'subscriptionPlanCreationMissingUnitPrice',
    [ErrorCode.MISSING_MONEY_FIELDS]: 'missingMoneyFields',
    [ErrorCode.STRIPE_INVALID_WEBHOOK]: 'stripeInvalidWebhook',
    [ErrorCode.WALLET_INSUFFICIENT_CREDITS]: 'walletInsufficientCredits',
    [ErrorCode.SUBSCRIPTION_PLAN_IS_NOT_ACTIVE]: 'subscriptionPlanIsNotActive',
    [ErrorCode.WALLET_INVALID_ACTION]: 'walletInvalidAction',
    [ErrorCode.COMMISSION_TYPE_NOT_ALLOWED_IN_TRANSACTION]: 'commissionTypeNotAllowedInTransaction',
    [ErrorCode.USER_HAS_NOT_ACCESS_TO_ORGANIZATION_WALLET]: 'userHasNotAccessToOrganizationWallet',
    [ErrorCode.INVALID_ORDER_ITEMS]: 'invalidOrderItems',
    [ErrorCode.STRIPE_UNABLE_TO_PROCEED_WITH_PAYMENT_INTENT]: 'stripeUnableToProceedWithPaymentIntent',
    [ErrorCode.PERSONAL_WALLET_CANNOT_HAVE_SUBSCRIPTION]: 'personalWalletCannotHaveSubscription',
    [ErrorCode.PAYMENT_INTENT_NEEDS_TO_HAVE_AT_LEAST_CREDITS_OR_SUBSCRIPTION_PLAN]: 'paymentIntentNeedsToHaveAtLeastCreditsOrSubscriptionPlan',
    [ErrorCode.CONTRACT_CANNOT_BE_REPLACED]: 'contractCannotBeReplaced',
    [ErrorCode.INVALID_RESOURCE_ACCESS]: 'invalidResourceAccess',
    [ErrorCode.MISSING_SUBSCRIPTION]: 'missingSubscription',
    [ErrorCode.FEATURE_NOT_SUBSCRIBED]: 'featureNotSubscribed',
    [ErrorCode.FEATURE_QUOTA_ENDED]: 'featureQuotaEnded',
    [ErrorCode.FEATURE_IS_PAID]: 'featureIsPaid',
    [ErrorCode.WALLET_DOESNT_MATCH_WITH_BASKET]: 'walletDoesNotMatchWithBasket',
    [ErrorCode.WALLET_DOESNT_MATCH_WITH_USER]: 'walletDoesNotMatchWithUser',
    [ErrorCode.STRIPE_PAYMENT_FAILED]: 'stripePaymentFailed',
    [ErrorCode.STRIPE_DEFAULT_PAYMENT_METHOD_NOT_CONFIGURED]: 'stripeDefaultPaymentMethodNotConfigured',
    [ErrorCode.WALLET_ACCESS_DENIED_ORGANIZATION]: 'walletAccessDeniedOrganization',
    [ErrorCode.WALLET_ACCESS_DENIED_PERSONAL]: 'walletAccessDeniedPersonal',
    [ErrorCode.FEATURE_TYPE_SIGNATURE_MAPPER_NOT_IMPLEMENTED]: 'featureTypeSignatureMapperNotImplemented',
    [ErrorCode.PURCHASE_ITEM_RESOURCE_TYPE_NOT_IMPLEMENTED]: 'purchaseItemResourceTypeNotImplemented',
    [ErrorCode.MISSING_WALLET_FOR_PURCHASE_PAYMENT]: 'missingWalletForPurchasePayment',
    [ErrorCode.MISSING_ASSET_ID_FIELD]: 'missingAssetIdField',
    [ErrorCode.WALLET_HAS_ACTIVE_SUBSCRIPTION]: 'walletHasActiveSubscription',
    [ErrorCode.INVALID_FEATURE_TYPE]: 'invalidFeatureType',
    [ErrorCode.SUBSCRIPTION_ALREADY_CANCELED]: 'subscriptionAlreadyCanceled',
    [ErrorCode.CONTRACT_IS_VOIDED]: 'contractIsVoided',
    [ErrorCode.UNAUTHORIZED]: 'unauthorized',
    [ErrorCode.BAD_REQUEST]: 'badRequest',
    [ErrorCode.ERR_NETWORK]: 'errorNetWork',
    [ErrorCode.ERR_CANCELED]: 'errorCanceled',
    [ErrorCode.TOO_MANY_REQUESTS]: 'tooManyRequests',
    [ErrorCode.INTERNAL_SERVER_ERROR]: 'internalServerError',
    [ErrorCode.PAYLOAD_TOO_LARGE]: 'payloadTooLarge',
};

export interface ErrorResponse extends Partial<ApiFieldErrors> {
    errors: FunctionalError[];
}

export interface ApiFieldError {
    [x: string]: string | number | undefined;
    object: string;
    typeOfViolation: string;
    message: string;
}

export type ApiFieldErrors = {
    fields: {
        [k: string]: Array<ApiFieldError>;
    };
}

export interface ErrorFormat {
    message: string;
    errorCode: ErrorCodeType;
}

export class FunctionalError extends Error {
    name: string;

    errorCode: ErrorCodeType;

    fieldName: string | undefined;
  
    constructor(message: string, errorCode: ErrorCodeType, fieldName?: string) {
        super(message);
        this.name = 'FunctionalError';
        this.errorCode = errorCode;
        this.fieldName = fieldName;
    }

    getMessageTranslated(t: (s: string) => string) {
        const translatedMessage = t(`errors.${ErrorCodeMap[this.errorCode]}`);

        // check if the translation is not available
        if (!translatedMessage || translatedMessage === `errors.${ErrorCodeMap[this.errorCode]}`) {
            return this.message;
        }
        return translatedMessage;
    }
}

/**
 * Return object with data and error
 */
export type DataOrError<T, E = FunctionalError | Error> = [T, null] | [null, E];

/**
 * Return data or errors mapped in the response
 */
export type HttpDataOrError<T, E = ErrorResponse> = [T, null] | [null, E];
