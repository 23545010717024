/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { ReactComponent as UserProfileIcon } from '../../assets/images/user-profile.svg';
import { User } from '../../types/user';

interface OwnProps extends TranslationContext {
    user: User;
    setSignerStep?: () => void;
}

const UserCardComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        user,
        setSignerStep,
    } = props;

    return (
        <>
            <div className="user-card" data-testid={`user-card-${user.id}`} onClick={setSignerStep}>
                <div className="user-card__info">
                    <span className="user-card__info__icon-wrap">
                        <UserProfileIcon />
                    </span>
                    <div className="user-card__info__details">
                        <p title={user.fullName}>{user.fullName}</p>
                        <p className="user-card__info__details__role">{user.contactEmail}</p>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export const UserCard = withTranslationContext(UserCardComponent);
