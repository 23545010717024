/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg';
import { AppRoute } from '../../../../constants/routes';
import { Contract, ContractConfigOption } from '../../../../types/contracts';
import { DataOrError, ErrorResponse } from '../../../../types/errors';
import { ButtonVariant } from '../../../../types/general';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import { OrganizationsContext, withOrganizationsContext } from '../../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import Button from '../../Button';
import Modal from '../../Modal';
import { Tooltip } from '../../Tooltip';
import { useContractContext } from '../ContractContextProvider';
import { buildUrl } from '../../../../utils/navigation';

interface OwnProps extends TranslationContext, OrganizationsContext, ContractsContext {
    isOpen?: boolean;
    hideDeleteButton?: boolean;
    onModalClose?: () => void;
    contractParam?: Contract;
}

/**
 *  Button action to delete a contract with confirmation
 * @param props
 */
const DeleteContractOptionBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        deleteContract,
        organizationSelected,
        isOpen,
        hideDeleteButton,
        onModalClose,
        contractParam,
    } = props;
    
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteResult, setDeleteResult] = useState<DataOrError<void, ErrorResponse>>();

    const navigate = useNavigate();

    const {
        contract,
    } = useContractContext();

    const modalOpenState = isOpen ?? isOpenModal;
    const handleCloseModal = onModalClose || (() => setIsOpenModal(false));

    const onOpenConfirmationModal = () => {
        if (isOpen === undefined) {
            setIsOpenModal(true);
        }
    };

    const onDeleteSuccess = () => {
        if (organizationSelected) {
            navigate(buildUrl(AppRoute.Contracts));
            return;
        }
        navigate(buildUrl(AppRoute.Contracts));
    };

    const onConfirmDelete = () => {
        setIsDeleting(true);
        
        deleteContract(contractParam ? String(contractParam.id) : String(contract?.id))
            .then((res) => {
                setDeleteResult(res);
                setIsDeleting(false);

                // If no error detected succeed
                if (res[0] === undefined) {
                    if (contractParam) {
                        handleCloseModal();
                        navigate(0);
                    } else {
                        onDeleteSuccess();
                    }
                }
            });
    };

    function renderError() {
        return deleteResult?.[1]?.errors.map((e) => (
            <p key={e.errorCode} data-testid="error-message" className="field-error">
                {e.getMessageTranslated(t)}
            </p>
        ));
    }

    return (
        <>
            { !hideDeleteButton
            && (
                <Button
                    variant={ButtonVariant.IconBtn}
                    data-step={ContractConfigOption.DeleteContract}
                    extraClasses={classNames('danger',
                        { active: isOpenModal })
                }
                    onClick={onOpenConfirmationModal}
                    testId="delete-btn"
                >
                    <Tooltip title={t('contractCreate.deleteContractTitle')} placement="left">
                        <DeleteIcon className="stroked" />
                    </Tooltip>
                </Button>
            )}
            <Modal
                open={modalOpenState}
                title={t('contractCreate.deleteContractTitle')}
                handleClose={handleCloseModal}
            >
                <div className="content-wrap">
                    <p>{t('contractCreate.deleteContractText')}</p>
                    <p className="warning-msg">{t('contractCreate.deleteContractWarning')}</p>
                    {renderError()}
                    {!isDeleting && (
                        <>
                            <Button
                                onClick={handleCloseModal}
                                variant={ButtonVariant.Curved}
                                extraClasses="secondary"
                                testId="cancel-btn"
                            >
                                {t('contractCreate.deleteContractCancel')}
                            </Button>
                            <Button
                                onClick={onConfirmDelete}
                                variant={ButtonVariant.Curved}
                                extraClasses="danger"
                                testId="confirm-btn"
                            >
                                {t('contractCreate.deleteContractSubmit')}
                            </Button>
                        </>
                    )}
                    {isDeleting && <CircularProgress color="primary" data-testid="loading" />}
                </div>
            </Modal>
        </>
    );
};

export const DeleteContractOption = withTranslationContext(withOrganizationsContext(withContractsContext(DeleteContractOptionBase)));
