/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ChevronRightIcon } from '../../../assets/images/chevron-right.svg';
import { AppRoute } from '../../../constants/routes';
import { OrderRequestPayload, Wallet } from '../../../types/billing';
import { ErrorResponse } from '../../../types/errors';
import { Permissions } from '../../../types/permissions';
import { ResourceType, WorkflowResponseType, WorkflowType } from '../../../types/workflows';
import { buildUrl } from '../../../utils/navigation';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import HasPermission from '../../elements/HasPermission';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { QuotaLimitExceededMessage } from '../../elements/workflows/QuotaLimitExceededMessage';
import { LoadingScreen } from '../LoadingScreen';

type OwnProps = TranslationContext & WorkflowContext & BillingContext & OrganizationsContext;

const CreateWorkflowSelectTypeScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
        getOrganizationWallet,
        getWorkflowTypes,
        simulateOrder,
        setWalletInfo,
    } = props;

    const navigate = useNavigate();

    const [isVerifyingQuota, setIsVerifyingQuota] = useState<boolean>(true);
    const [checkQuotaError, setCheckQuotaError] = useState<null | ErrorResponse>(null);
    const [costToOpenWorkflow, setCostToOpenWorkflow] = useState(0);
    const [isLoadingTypes, setIsLoadingTypes] = useState<boolean>(false);
    const [workflowTypes, setWorkflowTypes] = useState<WorkflowResponseType[]>([]);
    const [walletHasCredits, setWalletHasCredits] = useState(true);
    useEffect(() => {
        verifyQuotaToOpenWorkflow();
    }, []);

    useEffect(() => {
        if (checkQuotaError) throw checkQuotaError;
    }, [checkQuotaError]);

    /**
     * Verify if subscribed plan was enough quota
     *
     * @remarks
     * Simulate order to verify if there is enough quota to open new workflow
     * if so, call prepareScreen() if not, ask for the payment
     */
    const verifyQuotaToOpenWorkflow = async () => {
        if (!organizationSelected?.organization.id) return;

        const [walletData, walletRequestError] = await getOrganizationWallet(String(organizationSelected.organization.id));

        if (!walletData) {
            toast.error(t('errors.noAccessToOrganizationWallet'));
            return;
        }
        if (walletRequestError) {
            setCheckQuotaError(walletRequestError);
        }
        const payload: OrderRequestPayload = {
            walletContextId: walletData?.id ?? 0,
            resourceType: ResourceType.TRANSACTION,
        };

        const [orderData, requestError] = await simulateOrder(payload);
        if (orderData) {
            if (orderData.totalCost.amount > 0) {
                setCostToOpenWorkflow(orderData.totalCost.amount);
                setIsVerifyingQuota(false);
                return;
            }
            prepareScreen();
        }

        if (requestError) {
            setCheckQuotaError(requestError);
        }

        setIsVerifyingQuota(false);
    };

    const prepareScreen = async () => {
        setIsLoadingTypes(true);

        const [workflowTypesData] = await getWorkflowTypes();

        if (workflowTypesData) {
            setWorkflowTypes(workflowTypesData);
        }

        setIsLoadingTypes(false);
    };

    const handleSelectedType = (selectedType: WorkflowType) => {
        navigate(buildUrl(AppRoute.CreateWorkflowInfo, { type: selectedType }));
    };

    const getTypeDescription = (workflowType: WorkflowResponseType) => {
        const translatedText = t(`createWorkflowSelectType.${workflowType.transactionType}`);

        // Option not yet mapped locally
        if (translatedText === `createWorkflowSelectType.${workflowType.transactionType}`) {
            return workflowType.description;
        }

        return translatedText;
    };

    /**
     * Handle Confirm Payment
     *
     * @remarks
     * This function is called on "Conform Payment" button click
     */
    const handleConfirmPayment = async (wallet: Wallet) => {
        const payload: OrderRequestPayload = {
            walletContextId: wallet.id,
            resourceType: ResourceType.TRANSACTION,
        };

        const [orderData, requestError] = await simulateOrder(payload);

        if (orderData) {
            if (orderData.walletCreditsMissing.amount > 0) {
                setWalletHasCredits(false);
                setIsVerifyingQuota(false);
                return;
            }
            setWalletInfo(wallet);
        }
        if (requestError) {
            setCheckQuotaError(requestError);
        }

        setIsVerifyingQuota(false);
    };

    return (
        <HasPermission permissions={[Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]}>
            <DefaultLayout>
                {isVerifyingQuota && (<LoadingScreen />)}
                {!isVerifyingQuota && costToOpenWorkflow > 0 && (
                    <QuotaLimitExceededMessage
                        creditsCost={costToOpenWorkflow}
                        walletHasCredits={walletHasCredits}
                        onConfirmPayment={handleConfirmPayment}
                    />
                )}
                {!isVerifyingQuota && costToOpenWorkflow === 0 && (
                    <div className="workflow-select-type">
                        <h1>{t('createWorkflowSelectType.title')}</h1>
                        {isLoadingTypes && (<LoadingCircles size="xs" variant="secondary" />)}
                        {!isLoadingTypes && (
                            <section>
                                {workflowTypes.map((w) => (
                                    <button
                                        key={w.transactionType}
                                        type="button"
                                        // disable button if the fetched WorkflowType isn't mapped in this app yet
                                        disabled={!Object.values(WorkflowType).find((k) => k === w.transactionType)}
                                        onClick={() => handleSelectedType(w.transactionType)}
                                        data-testid={`workflow-select-type-${w.transactionType}`}
                                    >
                                        <span>{getTypeDescription(w)}</span>
                                        <ChevronRightIcon />
                                    </button>
                                ))}
                            </section>
                        )}
                    </div>
                )}
            </DefaultLayout>
        </HasPermission>
    );
};

export const CreateWorkflowSelectTypeScreen = withTranslationContext(withWorkflowContext(withBillingContext(withOrganizationsContext(CreateWorkflowSelectTypeScreenComponent))));
