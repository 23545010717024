import React, { FunctionComponent, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ReactComponent as ChevronDown } from '../../assets/images/arrow-down-broken.svg';
import { ActionFormatted } from '../../types/contracts';

type OwnProps = {
  options: ActionFormatted[];
  onAction: (action: ActionFormatted) => void;
};

export const ButtonDropdown: FunctionComponent<OwnProps> = ({ options, onAction }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    // Title is the label of the first option (which we will trigger)
    const title = options.length > 0 ? options[0].label : '';

    const handleToggle = () => setOpen((prev) => !prev);

    const handleClose = (event: Event) => {
        if (anchorRef.current?.contains(event.target as HTMLElement)) return;
        setOpen(false);
    };

    // Handle title button click, which will trigger the first option's action
    const handleTitleClick = () => {
        if (options.length > 0) {
            onAction(options[0]); // Trigger the first action
        }
    };

    return (
        <React.Fragment>
            <ButtonGroup ref={anchorRef} className={`custom-button-group ${options[0]?.id === 1 ? 'blue' : 'grey'}`}>
                <Button onClick={handleTitleClick}>{title}</Button>
                {options.length > 1 && (
                    <Button size="small" onClick={handleToggle} data-testid="dropdown-button">
                        <ChevronDown />
                    </Button>
                )}
            </ButtonGroup>
            {options.length > 1 && ( // Only render the dropdown if there are multiple options
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem>
                                        {options.slice(1).map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                onClick={() => {
                                                    onAction(option);
                                                    setOpen(false);
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </React.Fragment>
    );
};

export default ButtonDropdown;
