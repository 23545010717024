/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Stack } from '@mui/material';
import classNames from 'classnames';
import {
    FunctionComponent,
    Suspense,
    lazy,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import { toast } from 'react-toastify';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import {
    ContractState, Placeholder, SignatureType, Signer,
} from '../../../types/contracts';
import { ErrorResponse } from '../../../types/errors';
import { useContractContext } from '../../elements/contracts/ContractContextProvider';
import { SignedContractModal } from '../../elements/contracts/SignedContractModal';
import { LoadingScreen } from '../LoadingScreen';
import { ReactComponent as SignIcon } from '../../../assets/images/pen.svg';
import { AppRoute } from '../../../constants/routes';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { BannerType, ButtonVariant } from '../../../types/general';
import { Permissions } from '../../../types/permissions';
import { ContractSocketEventType } from '../../../types/websocket';
import { buildUrl } from '../../../utils/navigation';
import { preparePageTitle } from '../../../utils/route';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Banner } from '../../elements/Banner';
import Button from '../../elements/Button';
import { SignerActionBar } from '../../elements/contracts/SignerActionBar';
import { SigningLayout } from '../../elements/contracts/layouts/SigningLayout';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { useWebsocketContext } from '../../elements/websocket/WebsocketContextProvider';
import { DownloadOption } from '../../elements/contracts/options/DownloadOption';
import { SignersOption } from '../../elements/contracts/options/SignersOption';
import { GeneralConfigOption } from '../../elements/contracts/options/GeneralConfigOption';
import { CancelContractOption } from '../../elements/contracts/options/CancelContractOption';
import { ShareComponent } from '../../elements/ShareComponent';

const PdfViewerWrapper = lazy(() => import('../../elements/pdfViewer/PdfViewerWrapper'));

type OwnProps = TranslationContext & ContractsContext;

const ShowContractScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;
    const { contractId = '' } = useParams();

    const [searchParams] = useSearchParams();
    const signSuccessScreen = searchParams.get('signSuccess') ?? '';

    const { user } = useAuthContext();
    const navigate = useNavigate();

    const pdfPagesListRef = useRef<VariableSizeList>();
    const [isFetchingContract, setIsFetchingContract] = useState(false);
    const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(null);
    const [isAllowedToSign, setIsAllowedToSign] = useState(false);
    const [isWaitingForWsEvent, setIsWaitingForWsEvent] = useState(true);
    const [qesVideoShowAsyncMessage, setQesVideoShowAsyncMessage] = useState(false);
    const connectionAlreadyInitiated = useRef(false);

    useEffect(() => {
        if (errorResponse) throw errorResponse;
    }, [errorResponse]);

    const {
        contract,
        contractPdfFileUrl,
        prepareContract,
        prepareContractPdf,
        placeholderList,
        pendingPlaceholder,
        updatePlaceholderInList,
        setCurrentPage,
        updatePendingPlaceholder,
    } = useContractContext();

    const {
        initConnection,
        addHandler,
        removeHandler,
        sendMessage,
        isWsConnecting,
    } = useWebsocketContext();

    const canView = useUserHasPermission([Permissions.VIEW_CONTRACT, Permissions.VIEW_ORGANIZATION_CONTRACTS, Permissions.VIEW_ALL_ORGANIZATION_CONTRACTS]);
    const canManage = useUserHasPermission([Permissions.MANAGE_CONTRACT, Permissions.MANAGE_ORGANIZATION_CONTRACTS, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]);
    const signerCurrentUser: Signer | undefined = useMemo(() => {
        return contract?.signers?.find((signer) => signer.signerUserId === user?.id);
    }, [contract, user]);
    
    useEffect(() => {
        setIsWaitingForWsEvent(isWsConnecting);
    }, [isWsConnecting]);

    useEffect(() => {
        document.title = preparePageTitle(t('contract.view'));
    }, []);

    useEffect(() => {
        init();
    }, [contractId]);

    useEffect(() => {
        if (signSuccessScreen && signerCurrentUser && signerCurrentUser.signatureType === SignatureType.QESVIDEO && signerCurrentUser.signedAt === null) {
            setQesVideoShowAsyncMessage(true);
        } else {
            setQesVideoShowAsyncMessage(false);
        }
        addHandler(ContractSocketEventType.ESIGNATURES_LOCK_GRANTED, handleSignerIsRedirectedToSign);
        addHandler(ContractSocketEventType.ESIGNATURES_LOCK_LOCKED, handleSignerIsNotAllowedToSign);
        addHandler(ContractSocketEventType.ESIGNATURES_LOCK_FREE, handleSignerIsAllowedToSign);
        addHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_DOCUMENT_SIGNED, getFinalQESVideoSignature);

        if (signerCurrentUser && !connectionAlreadyInitiated.current) {
            initConnection(String(signerCurrentUser.id));
        }

        return () => {
            if (signerCurrentUser) {
                connectionAlreadyInitiated.current = true;

                removeHandler(ContractSocketEventType.ESIGNATURES_LOCK_GRANTED, handleSignerIsRedirectedToSign);
                removeHandler(ContractSocketEventType.ESIGNATURES_LOCK_LOCKED, handleSignerIsNotAllowedToSign);
                removeHandler(ContractSocketEventType.ESIGNATURES_LOCK_FREE, handleSignerIsAllowedToSign);
                removeHandler(ContractSocketEventType.ESIGNATURES_QESVIDEO_DOCUMENT_SIGNED, getFinalQESVideoSignature);
            }
        };
    }, [signerCurrentUser]);

    const handleSignerIsNotAllowedToSign = useCallback(() => {
        setIsAllowedToSign(false);
        setIsWaitingForWsEvent(false);
    }, []);

    const handleSignerIsAllowedToSign = useCallback(() => {
        setIsAllowedToSign(true);
        setIsWaitingForWsEvent(false);
    }, []);

    const handleSignerIsRedirectedToSign = useCallback(() => {
        if (signerCurrentUser) navigate(buildSignRoute(signerCurrentUser));

        setIsWaitingForWsEvent(false);
    }, [signerCurrentUser]);

    const init = async () => {
        setIsFetchingContract(true);
        const contractResponse = await prepareContract(contractId);

        if (contractResponse) {
            setErrorResponse(contractResponse);
            setIsFetchingContract(false);
            return;
        }

        const pdfResponse = await prepareContractPdf(contractId);
        if (pdfResponse) {
            setErrorResponse(pdfResponse);
            setIsFetchingContract(false);
            return;
        }

        setErrorResponse(null);
        setIsFetchingContract(false);
    };
    const getFinalQESVideoSignature = () => {
        setTimeout(() => {
            init();
            setQesVideoShowAsyncMessage(false);
        }, 2000);
    };
    const buildSignRoute = (signer: Signer) => {
        let route;
        switch (signer.signatureType) {
            case SignatureType.QESCMD:
                route = AppRoute.CmdSignatureContext;
                break;
            case SignatureType.QESVIDEO:
                route = AppRoute.QesSignatureTermsAndCondition;
                break;
            case SignatureType.AES:
            default:
                route = AppRoute.AesSignaturePhone;
                break;
        }

        return buildUrl(route, {
            contractId: contractId?.toString() ?? '',
            signerId: signerCurrentUser?.id.toString() ?? '',
        });
    };

    const handleClickSignBtn = () => {
        sendMessage(ContractSocketEventType.ESIGNATURES_LOCK_REQUEST);
        setIsWaitingForWsEvent(true);
    };

    const signBtn = signerCurrentUser && !signerCurrentUser.signedAt && contract?.contractState === ContractState.SIGNING ? (
        <Button
            id="actionSign"
            variant={ButtonVariant.Curved}
            extraClasses="yellow stroked-icon"
            onClick={handleClickSignBtn}
            startIcon={<SignIcon />}
            testId="actionSign"
            disabled={!isAllowedToSign || isWaitingForWsEvent}
        >
            {isWaitingForWsEvent ? t('signingScreen.loading') : t('signingScreen.sign')}
        </Button>
    ) : undefined;
    const handleUpdatePlaceholder = (placeholder: Placeholder) => {
        updatePlaceholderInList(placeholder, onUpdatePlaceholderFailure);
    };

    const onUpdatePlaceholderFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };
    
    return (
        <DefaultLayout>
            {contract && (
                <>
                    <SigningLayout button={signBtn} goBackPath={contract.transactionId ? buildUrl(AppRoute.ViewWorkflow, { workflowId: String(contract.transactionId) }) : AppRoute.Contracts}>
                        <div
                            className={classNames(
                                'contract-screen light-gradient-bg information-page',
                                { 'contract-screen--create': contract?.contractState === ContractState.DRAFT },
                            )}
                        >
                            {contractPdfFileUrl && !isFetchingContract && (
                                <Suspense fallback={<LoadingScreen />}>
                                    <div className="pdf-viewer-max-width-content">
                                        <div className="contract-screen__pdf-viewer pdf-viewer-wrap">
                                            <PdfViewerWrapper
                                                fileUrl={contractPdfFileUrl}
                                                pdfPagesListRef={pdfPagesListRef}
                                                placeholderList={placeholderList}
                                                updatePlaceholderList={handleUpdatePlaceholder}
                                                updateCurrentPage={setCurrentPage}
                                                pendingPlaceholder={pendingPlaceholder || undefined}
                                                isPlaceholderDraggable={contract.contractState === ContractState.DRAFT}
                                                updatePendingPlaceholder={updatePendingPlaceholder}
                                            />

                                            <div className="contract-screen__pdf-viewer__buttons">
                                                {!pendingPlaceholder && (
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="flex-end"
                                                        spacing={0.5}
                                                    >
                                                        <GeneralConfigOption />

                                                        {(canView || canManage) && (
                                                            <SignersOption />
                                                        )}
                                                        
                                                        {contractPdfFileUrl && (
                                                            <DownloadOption
                                                                fileUrl={contractPdfFileUrl}
                                                                fileName={contract.name}
                                                            />
                                                        )}
                                                        <ShareComponent
                                                            name="contract"
                                                            shareTitle={t('contract.share.title')}
                                                            text={t('contract.share.text', { contract })}
                                                            url={window.location.href}
                                                            subject={t('contract.share.emailSubject', { contract })}
                                                            tooltipPosition="left"
                                                            buttonType={ButtonVariant.IconBtn}
                                                        />

                                                        {canManage && !contract.voided && contract.contractState !== ContractState.DRAFT && (
                                                            <CancelContractOption />
                                                        )}
                                                    </Stack>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {pendingPlaceholder && <SignerActionBar />}
                                </Suspense>
                            )}
                        </div>
                    </SigningLayout>
                    {signerCurrentUser && !signerCurrentUser.signedAt && !isAllowedToSign && !isWaitingForWsEvent && contract.contractState === ContractState.SIGNING && <Banner type={BannerType.INFO} title={t('signingScreen.signing_alert_title')}>{t('signingScreen.signing_alert')}</Banner>}
                    {contract.voided && contract.contractStateNotes && (<Banner type={BannerType.INFO} title={t('contract.contractCanceled')}>{contract.contractStateNotes}</Banner>)}
                    {qesVideoShowAsyncMessage && <Banner type={BannerType.INFO} title={t('contractView.qesVideoShowAsyncMessage.title')} onClose={() => setQesVideoShowAsyncMessage(false)}>{t('contractView.qesVideoShowAsyncMessage.description') }</Banner>}
                    <SignedContractModal timeToClose={15} />
                </>
            )}
        </DefaultLayout>
    );
};

export const ShowContractScreen = withTranslationContext(withContractsContext(ShowContractScreenComponent));
