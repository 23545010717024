/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';
import {
    DocumentChecklistAssignment,
    LocationPayload,
    ResourceType,
    WorkflowResourceRequestStatus,
} from '../types/workflows';
import { objectToParams } from '../utils/misc';

export const workflowTypesUrl = () => {
    return '/transactions/types';
};

export const workflowsUrl = () => {
    return '/transactions/';
};

export const workflowUrl = (workflowId: number) => {
    return `/transactions/${workflowId}`;
};

export const workflowPropertyUrl = (workflowId: number) => {
    return `/transaction/${workflowId}/property`;
};

export const workflowParticipantsUrl = (id: string, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/transactions/${id}/participants${queryStr}`;
};

export const workflowTransferOwnershipUrl = (transactionId: string) => {
    return `/transactions/${transactionId}/transfer`;
};

/* Location */
export const locationUrl = (params?: LocationPayload) => {
    const queryStr = objectToParams(params as KeyedObject<number>);
    return `/location${queryStr}`;
};

/* Property */
export const propertyTypesUrl = () => {
    return '/property/types';
};

export const propertyTypologiesUrl = (params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/property/typologies${queryStr}`;
};

/* States */
export const workflowStatesUrl = (params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/transaction/state/states${queryStr}`;
};

export const workflowAvailableStatesUrl = (workflowId: number) => {
    return `/transaction/state/${workflowId}/state`;
};

export const workflowStateChangeUrl = (workflowId: number) => {
    return `/transaction/state/${workflowId}/change`;
};

/* Contracts */
export const workflowContractsUrl = (workflowId: number) => {
    return `/transaction/${workflowId}/contract/list`;
};

export const workflowContractUrl = (workflowId: number) => {
    return `/transaction/${workflowId}/contract`;
};

/* Documents */
export const workflowDocumentListUrl = (id: string, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `transaction/${id}/document/list${queryStr}`;
};

export const workflowDocumentUrl = (workflowId: string, documentId?: string) => {
    const documentIdParam = documentId ? `/${documentId}` : '';
    return `transaction/${workflowId}/document${documentIdParam}`;
};

export const workflowReviewDocumentUrl = (workflowId: string, documentId: string) => {
    return `/transaction/${workflowId}/document/${documentId}/review`;
};

export const workflowDocumentDownloadUrl = (workflowId: string, documentId: string) => {
    return `/transaction/${workflowId}/document/${documentId}/download`;
};

export const documentTypeUrl = () => {
    return '/document-types';
};

export const workflowAssignmentsUrl = (id: string, assignmentType: DocumentChecklistAssignment, missingAction?: boolean) => {
    const queryStr = objectToParams({ missingAction });

    return `transactions/${id}/assignments/${assignmentType}${queryStr}`;
};

export const workflowResourceRequestUrl = (workflowId: string, resourceId: string, resourceType: ResourceType, status?: WorkflowResourceRequestStatus) => {
    const queryStr = objectToParams({ status });

    return `transaction/${workflowId}/request/${resourceId}/${resourceType}${queryStr}`;
};

export const workflowReviewPendingRequestUrl = (workflowId: string, requestedAccessUuid: string) => {
    return `transaction/${workflowId}/request/${requestedAccessUuid}/review`;
};

export const workflowResourceRequestAccessUrl = (workflowId: string, resourceId: string) => {
    return `transaction/${workflowId}/request/${resourceId}/access`;
};

export const workflowParticipantPermissionsUrl = (workflowId: string) => {
    return `/transactions/${workflowId}/permissions`;
};

export const participantInvitationUrl = (inviteToken: string) => {
    return `${window.location.origin}/transaction/${inviteToken}`;
};
