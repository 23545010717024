/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { BadgeTextSize, TextBadgeColor, TextBadgeType } from '../../types/textBadge';

interface OwnProps {
    text: string;
    type?: TextBadgeType;
    color?: TextBadgeColor;
    fontSize?: BadgeTextSize;
    mediumPadding?: boolean;
    icon?: ReactNode;
}

const TextBadge: FunctionComponent<OwnProps> = ({
    text,
    type = TextBadgeType.Filled,
    color = TextBadgeColor.Purple,
    fontSize = BadgeTextSize.Medium,
    mediumPadding = false,
    icon,
}) => {
    return (
        <span className={classNames('text-badge', {
            'text-badge--filled': type === TextBadgeType.Filled,
            'text-badge--outlined': type === TextBadgeType.Outlined,
            'text-badge--text-medium': fontSize === BadgeTextSize.Medium,
            'text-badge--text-small': fontSize === BadgeTextSize.Small,
            'text-badge--purple': color === TextBadgeColor.Purple,
            'text-badge--gray': color === TextBadgeColor.Gray,
            'text-badge--green': color === TextBadgeColor.Green,
            'text-badge--yellow': color === TextBadgeColor.Yellow,
            'text-badge--red': color === TextBadgeColor.Red,
            'text-badge--medium-padding': mediumPadding,
        })}
        >
            {icon}{text}
        </span>
    );
};

export default TextBadge;
