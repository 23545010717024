/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Avatar,
    AvatarGroup,
    Button,
    ListItem,
    ListItemText,
    Menu,
} from '@mui/material';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    CurrencyInfo, Participant, Workflow,
} from '../../../types/workflows';
import {
    TranslationContext,
    withTranslationContext,
} from '../../controllers/TranslationContext';

import availableCurrencies from '../../../assets/data/available-currencies.json';
import { AppRoute } from '../../../constants/routes';
import { ProgressStep } from '../../../types/general';
import { buildUrl } from '../../../utils/navigation';
import { formatNumberToLocale } from '../../../utils/number';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { ProgressStepper } from '../ProgressStepper';

interface OwnProps extends TranslationContext, WorkflowContext {
    workflow: Workflow;
}

const WorkflowCardBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        workflow,
        getWorkflowParticipants,
        getWorkflowStates,
    } = props;

    const [participants, setParticipants] = useState<Participant[]>([]);
    const [totalParticipants, setTotalParticipants] = useState<number>(0);
    const [workflowSteps, setWorkflowSteps] = useState<ProgressStep[]>([]);
    const currentStateInHappyPath = workflowSteps.some((step) => step.value === workflow?.status);

    const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
    const isOpenSignersMenu = Boolean(anchorMenu);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const [participantsData] = await getWorkflowParticipants(String(workflow.id), '', '30');

        if (participantsData) {
            setParticipants(participantsData.results);
            setTotalParticipants(participantsData.totalAmount ?? participantsData.results.length ?? 0);
        }

        const [states] = await getWorkflowStates(workflow.transactionType);
        if (states) {
            const mappedSteps = states.map((item) => {
                return {
                    value: item.transactionState,
                    label: t(`workflows.view.${workflow.transactionType}.steps.${item.transactionState}`),
                };
            });
            setWorkflowSteps(mappedSteps);
        }
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorMenu(event.currentTarget);
    };

    const handleClose = (event: { stopPropagation: () => void }) => {
        setAnchorMenu(null);
        event.stopPropagation();
    };

    const getDefaultTitle = () => {
        const propertyType = workflow?.property?.propertyType ? t(`propertyTypes.${workflow.property.propertyType}`) : '';
        const typology = workflow?.property?.typology ? t(`propertyTypologies.${workflow.property.typology}`) : '';
        const parish = workflow?.property?.parish ? `${t('workflows.in')} ${workflow.property.parish.name}` : '';

        let defaultTitle = '';

        if (propertyType) defaultTitle += `${propertyType} `;
        if (typology) defaultTitle += `${typology} `;
        if (defaultTitle && parish) defaultTitle += parish;

        return defaultTitle;
    };
    return (
        <Link
            to={buildUrl(AppRoute.ViewWorkflow, { workflowId: String(workflow.id) })}
            key={`link-${workflow.id}`}
            data-testid="workflow-card-link"
        >
            <div
                className={classNames(
                    'workflow-card card',
                )}
                data-testid="card"
            >
                <div className="workflow-card__info">
                    <div className="workflow-card__info__header">
                        <div className="workflow-card__info__header__left">
                            {workflow.externalId}
                        </div>
                        {participants.length > 0 && (
                            <div className="workflow-card__info__header__right">
                                <Button
                                    className="workflo w-card__info__header__right__participants"
                                    aria-controls={isOpenSignersMenu ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isOpenSignersMenu ? 'true' : undefined}
                                    onClick={handleClick}
                                    data-testid="participants-btn"
                                >
                                    <AvatarGroup max={4} total={totalParticipants} sx={{ width: 28, height: 28 }}>
                                        {participants.map((participant) => {
                                            return (
                                                <Avatar key={participant.id} className="participant-avatar" alt={participant.name}>
                                                    {participant.name.split(' ')[0][0]}{participant.name.split(' ')[1] ? participant.name.split(' ')[1][0] : ''}
                                                </Avatar>
                                            );
                                        })}
                                    </AvatarGroup>
                                </Button>
                                <Menu
                                    className="workflow-card__info__header__right__participants"
                                    anchorEl={anchorMenu}
                                    open={isOpenSignersMenu}
                                    onClose={handleClose}
                                    onClick={(e) => e.stopPropagation()}
                                    MenuListProps={{
                                        'aria-labelledby': 'signers-menu',
                                    }}
                                >
                                    {participants.map((participant) => (
                                        <ListItem key={participant.inviteEmail}>

                                            <ListItemText
                                                primary={participant.name}
                                            />
                                        </ListItem>
                                    ))}
                                </Menu>

                            </div>
                        )}

                    </div>
                    <div className="workflow-card__info__type">
                        {t(`createWorkflowSelectType.${workflow.transactionType}`)}
                    </div>
                    <h3 className="workflow-card__info__title">
                        {workflow.title || getDefaultTitle()}
                    </h3>
                    {workflow.property && (
                        <div className="workflow-card__info__location">
                            {workflow.property?.county ? `${workflow.property?.county.name}, ` : ''}{workflow.property?.district ? workflow.property?.district?.name : ''}
                        </div>
                    )}
                    <div className="workflow-card__info__value">
                        {`${formatNumberToLocale(workflow.value.value)} ${(availableCurrencies as { [index: string]: CurrencyInfo })[workflow.value.currency].symbol}`}
                    </div>
                    <div className="workflow-screen--view__general-info__state">
                        {workflowSteps && currentStateInHappyPath ? (
                            <ProgressStepper steps={workflowSteps} currentStep={workflow.status} />
                        ) : <div>{t(`workflows.view.${workflow.transactionType}.steps.${workflow.status}`)}</div>}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export const WorkflowCard = withTranslationContext(withWorkflowContext(WorkflowCardBase));
