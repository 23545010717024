/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import availableCurrencies from '../../../assets/data/available-currencies.json';
import { AppRoute } from '../../../constants/routes';
import { Permissions } from '../../../types/permissions';
import {
    CommissionType, WorkflowFrequencyType, WorkflowPayload, WorkflowType,
} from '../../../types/workflows';
import { buildUrl } from '../../../utils/navigation';
import { FormValidationError } from '../../../utils/validations';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import HasPermission from '../../elements/HasPermission';
import { NotFoundPage } from '../NotFoundPage';
import { WorkflowSellInfoScreen } from './WorkflowSellInfoScreen';
import { WorkflowRentalInfoScreen } from './WorkflowRentalInfoScreen';

type OwnProps = TranslationContext & WorkflowContext & BillingContext;

const CreateWorkflowInfoScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        billingInfo,
        t,
        createWorkflow,
        validateWorkflow,
    } = props;

    const { type = '' } = useParams();
    const navigate = useNavigate();

    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const defaultWorkflowSell: WorkflowPayload = {
        title: '',
        description: '',
        transactionType: type.toLocaleUpperCase() as WorkflowType,
        commissionType: CommissionType.PERCENTAGE,
        commission: {
            value: null,
            currency: availableCurrencies.EUR.code,
        },
        value: {
            value: null,
            currency: availableCurrencies.EUR.code,
        },
        frequencyType: WorkflowFrequencyType.NONE,
        cmi: '',
        expiresAt: '',
        externalId: '',
    };

    const defaultWorkflowRent: WorkflowPayload = {
        title: '',
        description: '',
        transactionType: type.toLocaleUpperCase() as WorkflowType,
        commissionType: CommissionType.RENT,
        commission: {
            value: null,
            currency: availableCurrencies.EUR.code,
        },
        value: {
            value: null,
            currency: availableCurrencies.EUR.code,
        },
        frequencyType: WorkflowFrequencyType.MONTHLY,
        cmi: '',
        expiresAt: '',
        externalId: '',
    };

    /**
     * Submit Create Workflow
     *
     * @remarks
     * Submit fields
     */
    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>, workflow: WorkflowPayload) => {
        e.preventDefault();
        setIsLoading(true);
        const payload = {
            ...workflow,
            walletForPaymentId: billingInfo?.wallet?.id ?? undefined,
        };

        const errors = validateWorkflow(payload);
        setErrorsForm(errors);

        if (!errors && payload) {
            const [newWorkflow, submittingError] = await createWorkflow(payload);

            if (newWorkflow) {
                navigate(buildUrl(AppRoute.WorkflowProperty, { workflowId: String(newWorkflow.id) }));

                return;
            }

            if (submittingError) {
                toast.error(submittingError.errors[0].getMessageTranslated(t));
            }
        }

        setIsLoading(false);
    };

    const renderWorkflowInfoTypeScreen = () => {
        switch (type) {
            case WorkflowType.SELL:
                return (
                    <WorkflowSellInfoScreen
                        onFormSubmit={handleFormSubmit}
                        isLoading={isLoading}
                        errorsForm={errorsForm}
                        workflowDefaults={defaultWorkflowSell}
                    />
                );
            case WorkflowType.RENTAL:
                return (
                    <WorkflowRentalInfoScreen
                        onFormSubmit={handleFormSubmit}
                        isLoading={isLoading}
                        errorsForm={errorsForm}
                        workflowDefaults={defaultWorkflowRent}
                    />
                );
            default:
                return <NotFoundPage />;
        }
    };

    return (
        <HasPermission permissions={[Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]}>
            {renderWorkflowInfoTypeScreen()}
        </HasPermission>
    );
};

export const CreateWorkflowInfoScreen = withTranslationContext(withWorkflowContext(withBillingContext(CreateWorkflowInfoScreenBase)));
