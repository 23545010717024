/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as LockIcon } from '../../../assets/images/lock.svg';
import { AppRoute } from '../../../constants/routes';
import { ErrorCode, ErrorResponse } from '../../../types/errors';
import { ButtonVariant } from '../../../types/general';
import { buildUrl } from '../../../utils/navigation';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import Button from '../../elements/Button';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { ResourceType } from '../../../types/workflows';

interface OwnProps extends TranslationContext, WorkflowContext {
    workflowId: number;
    resourceId: number;
    resourceType: ResourceType;
}

const UnauthorizedAssetScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        requestAccessToWorkflowResource,
        workflowId,
        resourceId,
        resourceType,
    } = props;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ErrorResponse | null>(null);

    const requestForResourceAccess = () => {
        setIsLoading(true);

        requestAccessToWorkflowResource(String(workflowId), String(resourceId), resourceType).then((resourceAccessResponse) => {
            const [resourceAccess, resourceError] = resourceAccessResponse;
            
            if (resourceAccess?.status === 204) {
                toast.success(t('workflows.documents.requestedAccessToResource'));
                navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId: workflowId.toString() }));
                return;
            }
            if (resourceError) {
                setError(resourceError);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <div className="error-screen light-gradient-bg">
            <div className="error-screen__icon-container">
                <LockIcon />
            </div>
            <div className="error-screen__body">
                <h1>{t('workflows.documents.unauthorizedAsset')}</h1>
                {error && <p className="error-screen__body__red-error">{error.errors[0].getMessageTranslated(t)}</p>}
            </div>
            <div className="error-screen__actions">
                <Button
                    variant={ButtonVariant.Curved}
                    onClick={() => requestForResourceAccess()}
                    disabled={isLoading || error?.errors[0].errorCode === ErrorCode.ACTIVE_TRANSACTION_RESOURCE_REQUEST_IN_PLACE}
                >
                    {isLoading ? <LoadingCircles size="s" variant="neutral" /> : t('workflows.documents.askPermission')}
                </Button>
                <Button
                    onClick={() => navigate(-1)}
                >
                    {t('workflows.documents.goBackAction')}
                </Button>
            </div>
        </div>
    );
};

export const UnauthorizedAssetScreen = withTranslationContext(withWorkflowContext(UnauthorizedAssetScreenComponent));
